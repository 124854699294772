import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';

import { routePaths } from '@asecrmc/common/core/utils/route-paths';
import { AuthService } from '@asecrmc/common/core/services/auth.service';

/** App toolbar component. */
@Component({
	selector: 'asecrmw-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatToolbar,
		RouterLink,
		MatAnchor,
		RouterLinkActive,
		MatIconButton,
		MatIcon,
	],
})
export class ToolbarComponent {
	private readonly authService = inject(AuthService);

	/** Route paths. */
	public readonly routePaths = routePaths;

	/** Logout. */
	public logout(): void {
		this.authService.logout()
			.pipe(first())
			.subscribe();
	}
}
